import { saasModernTheme } from 'common/theme/saasModern';
import BannerArc from 'common/assets/image/saasModern/banner-arc.png';
import styled from 'styled-components';

export const SectionWhySwiftask = styled.section`
  position: relative;
`;

export const ContentHeader = styled.header`
  width: 60%;
  z-index: 10;
  height: 100%;
  mix-blend-mode: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 950px) {
    width: 100%; // Ajustez ce pourcentage selon vos besoins
  }
`;

export const Cloud = styled.div`
  width: 20%; // Ajustez ce pourcentage selon vos besoins
  left: 40%;
  position: absolute;
  border-radius: 446px;
  opacity: 0.2;
  background: #fc4ff6;
  top: -5%;
  z-index: 0;
  filter: blur(100px);
  &:before {
    content: '';
    display: block;
    padding-bottom: 100%; // Ceci maintient le rapport d'aspect 1:1 pour le carré
  }
  @media (max-width: 950px) {
    width: 40%; // Ajustez ce pourcentage selon vos besoins
    left: 30%;
    top: -25%;
  }
`;

export const HeaderWhySwiftask = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 0;
  text-align: center;
  width: 100%;
  background-image: url(${BannerArc?.src});
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: ${saasModernTheme.space[17]}px;
  position: relative;
  z-index: 5;
  @media (max-width: 1680px) {
    background-size: contain;
  }
  @media (max-width: 950px) {
    padding-top: ${saasModernTheme.space[8]}px;
  }
`;

export const ParentCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${saasModernTheme.space[8]}px;
`;

export const ChildCard = styled.div`
  align-self: ${(props) => (props.align ? props.align : '')};
  flex: 1;
  width: 70%;
  @media (max-width: 950px) {
    width: 60%;
  }
  @media (max-width: 550px) {
    width: 90%;
  }
`;

export const ContentCard = styled.article`
  display: grid;
  align-items: center;
  grid-template-columns: 50% 50%;
  gap: ${saasModernTheme.space[8]}px;
  flex-direction: row;
  min-height: 500px;
  @media (max-width: 950px) {
    display: block;
    min-height: auto;
    flex-direction: column;
  }
`;

export const ContentAside = styled.aside`
  width: 100%; // Ajustez ce pourcentage selon vos besoins
  z-index: 0;
  position: relative;
  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
  @media (max-width: 950px) {
    display: block;
    height: 100%;
  }
`;

export const ContentElement = styled.div`
  width: 90%;
  @media (max-width: 950px) {
    width: 100%;
  }
`;

export const Rectangle1 = styled.div`
  width: 10%;
  background-color: #f6ae2d;
  border-radius: 113.79px;
  height: 2%;
  left: -5%;
  position: absolute;
  top: -0.5%;
  transform: rotate(-22.21deg);
  @media (max-width: 950px) {
    width: 15%;
    height: 1%;
    left: -1.75%;
    top: -0.5%;
  }
  @media (max-width: 550px) {
    width: 15%;
    height: 1%;
    left: -1.75%;
    top: -0.5%;
  }
`;

export const Rectangle2 = styled.div`
  width: 7%;
  background-color: #1098f7;
  border-radius: 113.79px;
  height: 2.5%;
  top: -1.5%;
  left: 68%;
  transform: rotate(-22.21deg);
  position: absolute;
  @media (max-width: 950px) {
    width: 12.75%;
    height: 1.5%;
    left: 55%;
    top: -1%;
  }
  @media (max-width: 550px) {
    left: 80%;
    width: 17.75%;
    height: 1.2%;
    top: -0.75%;
  }
`;

export const Rectangle3 = styled.div`
  width: 75%;
  background-color: #ff6e72;
  border-radius: 690.76px;
  height: 7%;
  left: 25%;
  top: 11%;
  transform: rotate(-22.21deg);
  position: absolute;
  @media (max-width: 950px) {
    height: 3%;
    top: 14%;
  }
  @media (max-width: 550px) {
    height: 2%;
    top: 14%;
  }
  @media (max-width: 340px) {
    left: 22.5%;
  }
`;

export const Rectangle4 = styled.div`
  width: 30%;
  background-color: #f6ae2d;
  border-radius: 402.09px;
  height: 6%;
  left: 15%;
  top: 50%;
  transform: rotate(-105deg);
  position: absolute;
  @media (max-width: 950px) {
    top: 44%;
    left: 15.5%;
    width: 45%;
    height: 3%;
  }
  @media (max-width: 550px) {
    height: 2%;
    top: 45%;
    left: 0.5%;
  }
`;

export const Rectangle5 = styled.div`
  width: 10%;
  background-color: #f6ae2d;
  border-radius: 113.79px;
  height: 2%;
  left: -5.5%;
  top: 67%;
  transform: rotate(-22.21deg);
  position: absolute;
  @media (max-width: 950px) {
    width: 15%;
    height: 1%;
    left: -1.75%;
    top: 63.5%;
  }
  @media (max-width: 550px) {
    top: 63%;
  }
  @media (max-width: 340px) {
    top: 63%;
  }
`;

export const Rectangle6 = styled.div`
  width: 12%;
  background-color: #1098f7;
  border-radius: 263.71px;
  height: 4%;
  left: 65%;
  top: 65%;
  transform: rotate(-22.21deg);
  position: absolute;
  @media (max-width: 950px) {
    height: 1.75%;
    top: 63.5%;
    left: 55%;
    width: 15%;
  }
  @media (max-width: 550px) {
    height: 1.5%;
    width: 20.5%;
    left: 77%;
    top: 63%;
  }
  @media (max-width: 340px) {
    height: 1.5%;
    width: 20.5%;
    left: 75%;
    top: 63%;
  }
`;

export const Rectangle7 = styled.div`
  height: 10%;
  width: 80%;
  background-color: #ff6e72;
  border-radius: 690.76px;
  left: 0;
  top: 80%;
  transform: rotate(45.13deg);
  position: absolute;
  @media (max-width: 950px) {
    height: 3%;
    top: 80%;
    left: -8.5%;
  }
  @media (max-width: 340px) {
    height: 2%;
    top: 81.5%;
    left: -5.5%;
  }
`;

export const Cloud2 = styled.div`
  width: 20%; // Ajustez ce pourcentage selon vos besoins
  left: 0%;
  position: absolute;
  border-radius: 446px;
  opacity: 0.2;
  background: #fc4ff6;
  top: 20%;
  filter: blur(100px);
  &:before {
    content: '';
    display: block;
    padding-bottom: 100%; // Ceci maintient le rapport d'aspect 1:1 pour le carré
  }
  @media (max-width: 950px) {
    width: 40%; // Ajustez ce pourcentage selon vos besoins
    left: 30%;
    top: -25%;
  }
`;
