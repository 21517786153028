import styled, { keyframes } from 'styled-components';
import BannerArc from 'common/assets/image/saasModern/banner-arc-violet.svg';
import { saasModernTheme } from 'common/theme/saasModern';

export const ContentSVG = styled.div`
  padding: ${saasModernTheme.space[2]}px;
  background: url(${BannerArc?.src}) 0px 0px / 100% 100% no-repeat;
  mix-blend-mode: color-dodge;
  width: 30%;
  height: 40px;
  flex-shrink: 0;
  margin: 0 auto;
`;

export const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-250px * 7)); }
`;

export const SliderContainer = styled.div`
  background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 960px;

  &::before,
  &::after {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    content: '';
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }
`;

export const SlideTrack = styled.div`
  display: flex;
  gap: 24px;
  width: ${(props) =>
    props.length ? `calc(50px * ${props.length} )` : 'auto'};
  animation: ${scroll} 40s linear infinite;
`;

export const Slide = styled.div`
  height: 50px;
  width: 50px;
`;

export const Image = styled.img`
  height: 50px;
  width: 50px;
`;
