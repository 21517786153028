import React from 'react';
import { SlideTrack, Slide, Image, ContentSVG } from './aiStoreData.style';
import Container from 'common/components/Container';
import Link from 'next/link';

const AiStoreData = ({ aiStoreDatas }) => {
  return (
    <>
      <Container>
        <SlideTrack length={aiStoreDatas.length}>
          {aiStoreDatas.map((mybot, index) => (
            <Slide key={index}>
              <Link href={`ai/${mybot.slug}`}>
                <Image
                  src={mybot.profilePicture}
                  alt={mybot.name}
                  key={index}
                  data-aos='flip-left'
                />
              </Link>
            </Slide>
          ))}
        </SlideTrack>
      </Container>
      <ContentSVG />
    </>
  );
};

export default AiStoreData;
